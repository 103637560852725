<template>
  <!-- ======= Intro Section ======= -->
  <div class="intro intro-carousel swiper position-relative">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide carousel-item-a intro-item bg-image"
        style="background-image: url(assets/img/slide-1.jpg)"
      >
        <div class="overlay overlay-a"></div>
        <div class="intro-content display-table">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <!-- <div class="intro-body">
                    <p class="intro-title-top">
                      Doral, Florida <br />
                      78345
                    </p>
                    <h1 class="intro-title mb-4">
                      <span class="color-b">204 </span> Mount <br />
                      Olive Road Two
                    </h1>
                    <p class="intro-subtitle intro-price">
                      <a href="#"
                        ><span class="price-a">rent | $ 12.000</span></a
                      >
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="swiper-slide carousel-item-a intro-item bg-image"
        style="background-image: url(assets/img/slide-2.jpg)"
      >
        <div class="overlay overlay-a"></div>
        <div class="intro-content display-table">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <!-- <div class="intro-body">
                    <p class="intro-title-top">
                      Doral, Florida <br />
                      78345
                    </p>
                    <h1 class="intro-title mb-4">
                      <span class="color-b">204 </span> Rino <br />
                      Venda Road Five
                    </h1>
                    <p class="intro-subtitle intro-price">
                      <a href="#"
                        ><span class="price-a">rent | $ 12.000</span></a
                      >
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="swiper-slide carousel-item-a intro-item bg-image"
        style="background-image: url(assets/img/slide-3.jpg)"
      >
        <div class="overlay overlay-a"></div>
        <div class="intro-content display-table">
          <div class="table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <!-- <div class="intro-body">
                    <p class="intro-title-top">
                      Doral, Florida <br />
                      78345
                    </p>
                    <h1 class="intro-title mb-4">
                      <span class="color-b">204 </span> Alira <br />
                      Roan Road One
                    </h1>
                    <p class="intro-subtitle intro-price">
                      <a href="#"
                        ><span class="price-a">rent | $ 12.000</span></a
                      >
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
  <!-- End Intro Section -->

  <main id="main">
    <!-- ======= Services Section ======= -->
    <section class="section-services section-t8">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title-wrap d-flex justify-content-between">
              <div class="title-box">
                <h2 class="title-a">What We Do</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h4>Work with a real estate company that works for you!</h4>
            <p>
              BYSELL REAL ESTATE INC Real Estate is a Winnipeg, Manitoba real
              estate brokerage located at 883 Notre Dame Avenue. Since June 21,
              2021, we are providing trusted real estate services in all
              Winnipeg neighborhoods and surrounding communities. We can assist
              you with the purchase and sale of all properties like:
              Residential, Commercial, or Condominium. BYSELL REAL ESTATE is
              establishing a positive professional culture focused on our
              clients through good leadership and a collaborative unit of
              REALTORS® and staff. Currently, we have experienced REALTORS®
              working with us and we continue to grow! We are locally possessed
              and worked self- reliant real estate company. Pick one of our
              agents (representative) to buy or sell your place. or you can
              contact us at
              <span class="color-text-a"
                >Phone -
                <a href="tel:+1 (204)-306-1144">+1 (204)-306-1144</a>
              </span>
            </p>

            <p>
              Our office presently located at 1309 Mountain Ave, Winnipeg, MB
              R2X 2Y1
            </p>
            OR
            <p>
              You can reach us by email:
              <a href="mailto:bysellreception@gmail.com"
                >bysellreception@gmail.com</a
              >
            </p>
            <p>
              If you are an agent looking to join our brokerage, we offer good
              training and development in our Membership program, giving you the
              perfect chance to develop and maintain your own business. We have
              everything you need to rise above the competition. We can assist
              you with the purchase and sale of all properties like:
              Residential, Commercial, or Condominium. BYSELL REAL ESTATE is
              establishing a positive professional culture focused on our
              clients through good leadership and a collaborative unit of
              REALTORS® and staff.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card-box-c foo">
              <div class="card-header-c d-flex">
                <div class="card-box-ico">
                  <span class="bi bi-cart"></span>
                </div>
                <div class="card-title-c align-self-center">
                  <h2 class="title-c">Residential</h2>
                </div>
              </div>
              <div class="card-body-c">
                <p class="content-c">
                  The deal of family homes is the biggest source of our commerce
                  and we have done many deals of homes. Getting the foremost
                  cash for your house with the slightest bother to you is the
                  objective. Once you contract us to offer your domestic,
                  you&#39;re buying a demonstrated Promoting framework and a
                  Proficient Benefit.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-c foo">
              <div class="card-header-c d-flex">
                <div class="card-box-ico">
                  <span class="bi bi-calendar4-week"></span>
                </div>
                <div class="card-title-c align-self-center">
                  <h2 class="title-c">Condominiums</h2>
                </div>
              </div>
              <div class="card-body-c">
                <p class="content-c">
                  Condominiums have advanced from essential flat fashion to
                  extravagance family homes within the past few a long time.
                  There’s a fashion for everyone.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-c foo">
              <div class="card-header-c d-flex">
                <div class="card-box-ico">
                  <span class="bi bi-card-checklist"></span>
                </div>
                <div class="card-title-c align-self-center">
                  <h2 class="title-c">Commercial</h2>
                </div>
              </div>
              <div class="card-body-c">
                <p class="content-c">
                  Commercial property has traditionally been seen as a sound
                  investment. Initial investment costs for the building and
                  costs associated with customization for tenants are higher
                  than residential real estate.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-c foo">
              <div class="card-header-c d-flex">
                <div class="card-box-ico">
                  <span class="bi bi-card-checklist"></span>
                </div>
                <div class="card-title-c align-self-center">
                  <h2 class="title-c">Refereal</h2>
                </div>
              </div>
              <div class="card-body-c">
                <p class="content-c">
                  We will help you to find a loyal and talented Realtor for your
                  future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Services Section -->

    <!-- ======= Latest Properties Section ======= -->

    <!-- End Latest Properties Section -->

    <!-- ======= Agents Section ======= -->
    <section class="section-agents section-t8">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title-wrap d-flex justify-content-between">
              <div class="title-box">
                <h2 class="title-a">Our Team</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/avinash_chander.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Avinash Chander</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    Avinash Chander has gained much success in the real estate
                    industry and has become an expert in the area of investment
                    property. Avinash Chander is one of our company&#39;s most
                    valuable assets due to his superb training, experience, and
                    acquired depth of knowledge. Started carrier in Real Estate
                    Field in May 2015. After hard work achieved the motive as
                    Broker in June 2021.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> +1 (204) 930-8404</p>
                    <p><strong>Email: </strong> avinashchander@hotmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/Kuldeep_Singh.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Kuldeep Singh Maan</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    As a real estate professional, I recognize and value the
                    trust my clients place in me, and I strive every day to
                    exceed their expectations. Originally from Punjab India , Mr
                    Maan moved to Canada to earn a diploma in Hotel and
                    Management at Cambrian College. This knowledge, coupled with
                    his bachelor degree in education and teaching, has provided
                    him with valuable skills that he uses daily in real estate
                    negotiations, educating homebuyers and sellers, and
                    effectively marketing the listings.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-979-7700</p>
                    <p><strong>Email: </strong> realtorksmaan@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/shann_klyde_del_gardose.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Shann Klyde Del Gardose</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    “A professional home matchmaker, at your service!
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-430-2633</p>
                    <p><strong>Email: </strong> skgardose@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/Jagdev_Singh_Pannu.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Jagdev Singh Pannu</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    I am a proudly part of Bysell Real Estate. I am living in
                    Winnipeg from last 10 years. As per my previous work
                    experience I know very well about Winnipeg and surrounding
                    area like new developments, infill houses or country side
                    homes . I am passionate to work in real estate industry.
                    Apart from real estate I love to do volunteer work . We
                    regularly run blood donation campaigns, Street cleaning
                    campaigns etc. I'm proudly regular blood donor.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-930-3013</p>
                    <p><strong>Email: </strong> pannutharajrealtor@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/gurpreet_bhathal.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Gurpreet Singh Bhathal</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    I am a Post Graduate and B.Ed, worked for Punjab School
                    Education Department, moved to Canada in 2010. I am here to
                    help you with every kind of Real Estate needs. I assure you
                    that I am the person you can trust.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-891-0066</p>
                    <p><strong>Email: </strong> Gbhathal48@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/sarbjit_narang.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Sarabjit Kaur Narang</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    A highly motivated and ambitious working mother of 3
                    beautiful children. My family brings me utmost joy and
                    passion and I find ways and means to balance the best of
                    both worlds. I have dived into the world of Real Estate so I
                    can help people find their ideal home. Making a choice about
                    investing in your future is not easy and trust me to help
                    you do that effortlessly with trust and confidence. When I
                    am not working, I love listening to Indian music and
                    spending quality time with my family. I look forward to
                    speaking with you.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-952-7013</p>
                    <p><strong>Email: </strong> sarab_1983@hotmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/shariya_kalsi.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Shariya Kalsi</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    I’m a passionate, driven individual. I’m looking to serve my
                    clients not sell them. I love to put a smile on people's
                    faces and that is the reason I do real estate.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-898-6250</p>
                    <p><strong>Email: </strong> shariyakalsi541@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/ranjeet_galha.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Ranjeet Singh Galha</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    My prime objective is to ease and make enjoyable experience
                    of buying or selling your home throughout the process. My
                    experience in home renovations and as site supervisor of new
                    home building helps me to learn about Winnipeg homes,
                    standard, building codes and city inspections. So, lets
                    start with a chat.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-955-2917</p>
                    <p><strong>Email: </strong> ranjeetgalha@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/harshdeep_singh.jpeg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Harshdeep Singh</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">
                    <quote>“Your desires are what inspires”.</quote> If you are
                    wanting to buy or sell in real estate and are looking for
                    someone whom you can trust through this process, I will be
                    more than happy to assist you in this process.
                  </p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> 204-230-6602</p>
                    <p><strong>Email: </strong> realtorharsh77@gmail.com</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/gagan_chahal.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Gagan Chahal</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">#</p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> #</p>
                    <p><strong>Email: </strong> #</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/karam_singh.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Karam Singh</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">#</p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> #</p>
                    <p><strong>Email: </strong> #</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/oshima_kapoor.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Oshima Kapoor</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">#</p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> #</p>
                    <p><strong>Email: </strong> #</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-box-d">
              <div class="card-img-d">
                <img
                  src="assets/img/khushwant_brar.jpg"
                  alt=""
                  class="img-d img-fluid"
                />
              </div>
              <div class="card-overlay card-overlay-hover">
                <div class="card-header-d">
                  <div class="card-title-d align-self-center">
                    <h3 class="title-d">
                      <a href="#" class="link-two">Khushwant Brar</a>
                    </h3>
                  </div>
                </div>
                <div class="card-body-d">
                  <p class="content-d color-text-a">#</p>
                  <div class="info-agents color-a">
                    <p><strong>Phone: </strong> #</p>
                    <p><strong>Email: </strong> #</p>
                  </div>
                </div>
                <div class="card-footer-d">
                  <div class="socials-footer d-flex justify-content-center">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-twitter" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a href="#" class="link-one">
                          <i class="bi bi-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Agents Section -->

    <!-- ======= Testimonials Section ======= -->

    <!-- End Testimonials Section -->
  </main>
  <!-- End #main -->
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #050505;
}
</style>
